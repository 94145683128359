<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.detail') }}</span></div>
           <div>
             <select class="reqMember mr-5" v-model="searchSelect" @change="searchSelectChange">
              <option value="memId">{{ $t('searchArea.id') }}</option>
              <option value="memNick">{{ $t('searchArea.nick') }}</option>
              <option value="recommenderId">{{ $t('searchArea.recommenderId') }} </option>
            </select>
            <input type="text" class="mr-5" v-model="reqData[searchSelect]" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.choose') }}</span></div>
           <div class="fx">
             <template v-for="(item, idx) in checkList" :key="idx">
               <ui-check :checked="item.checked" :text="item.text" :name="item.name" :id="`checkedItem${idx}`" @setYn="setYn" />
             </template>
           </div>

         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.totalCnt`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
            <tr>
              <th rowspan="2" width="40px">{{ $t('table.head.idx') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.id') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.nick') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.recommenderId') }}</th>
              <!-- <th rowspan="2" class="rate-column">스포츠</th> -->
              <!-- <th rowspan="2" class="rate-column">미니게임</th> -->
              <!-- <th rowspan="2" class="rate-column">E-스포츠</th> -->
              <!-- <th rowspan="2" class="rate-column">키론</th> -->
              <!--  todo : rowspan 자동으로 넣을수있게 수정필요               -->
              <th v-if="openGroup === 'casino'" :colspan="casinoList.length" class="openHead" @click="setOpenGroup('casino')">{{ $t('table.head.casino') }}</th>
              <th v-if="openGroup !== 'casino'" rowspan="2" class="openHead rate-column" @click="setOpenGroup('casino')">{{ $t('table.head.casino') }}</th>

              <th v-if="openGroup === 'slot'" :colspan="slotList.length" class="openHead" @click="setOpenGroup('slot')">{{ $t('table.head.slot') }}</th>
              <th v-if="openGroup !== 'slot'" rowspan="2" class="openHead rate-column" @click="setOpenGroup('slot')">{{ $t('table.head.slot') }}</th>

              <th rowspan="2" class="rate-column">{{ $t('table.head.totalCashInAmt') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.totalCashOutAmt') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.totalCashDiff') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.wl') }}</th>
              <th rowspan="2" class="rate-column">{{ $t('table.head.memRegDt') }}<br />{{ $t('table.head.lastLogin') }}</th>
              <th rowspan="2" class="rate-column"></th>
            </tr>
            <tr>
              <template v-if="openGroup === 'casino'">
              <th class="rate-column" style="border:solid 1px #ddd;" v-for="item in casinoList" :key="item.code">{{item.text}}</th>
            </template>
            <template v-if="openGroup === 'slot'">
              <th class="rate-column" style="border:solid 1px #ddd;" v-for="item in slotList" :key="item.code">{{item.text}}</th>
            </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in memList" :key="idx">
              <td>{{idx+1}}</td>
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <span class="fc-nick">{{ item.memNick }}</span>
              </td>
              <td>{{ item.recommenderId }}</td>
              <!-- <td>{{ item.reRateList.sport[0].pointRate }}</td> -->
              <!-- <td>{{ item.reRateList.minigame[0].pointRate }}</td> -->
              <!-- <td>{{ item.reRateList.esport[0].pointRate }}</td> -->
              <!-- <td>{{ item.reRateList.kiron[0].pointRate }}</td> -->
              <!-- 카지노 -->
              <td v-if="openGroup !== 'casino'">{{ item.casinoRate }}</td>
              <template v-if="openGroup === 'casino'">
                <td v-for="child in item.reRateList.casino" :key="child.code">{{child.pointRate}}</td>
              </template>
              <!-- 슬롯 -->
              <td v-if="openGroup !== 'slot'">{{ item.slotRate }}</td>
              <template v-if="openGroup === 'slot'">
                <td v-for="child in item.reRateList.slot" :key="child.code">{{child.pointRate}}</td>
              </template>

              <td>{{ item.totalCashInAmt }}</td>
              <td>{{ item.totalCashOutAmt }}</td>
              <td>{{ item.totalCashDiff }}</td>
              <td>{{ item.totalWlAmt }}</td>
              <td class="row-double-data">
                <span>{{ item.regDt }}</span>
                <br>
                <span>{{ item.lastLoginDt }}</span>
              </td>
              <!-- <td v-for="rate in item.rateList" :key="rate.gameType"></td> -->
              <td width="5%">
                <button type="button" class="btn-layout btn-blue mr-0" @click="modalControl(item.memId)">{{ $t('table.head.set') }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="memList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
    <div class="modal-wrapper" v-if="modal.active">
      <div class="modal-wrap">
        <article class="">
           <sub-title :text="`${modal.target} - 롤링보너스`" />
          <!--button class="btn-link modal-close" type="button" @click="modalClose">
           <i class="fas fa-times-circle"></i>
          </button-->
          <div class="page-content">
             <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.rolling') }}</div>
                <div class="item-content fx">
                   <button type="button" class="btn-layout btn-red">{{ $t('common.maxSelect') }}</button>
                   <button type="button" class="btn-layout btn-red">{{ $t('common.minSelect') }}</button>
                   <input type="number" value="00.00" class="w70" />
                   <button type="button" class="btn-layout btn-blue">{{ $t('common.casinoApply') }}</button>
                   <button type="button" class="btn-layout btn-blue">{{ $t('common.slotApply') }}</button>
                </div>
              </div>
            </div>
            <!--template v-for="(item, index) in detailGameList" :key="index">
              <div class="item-row-container">
                <div class="item-wrap">
                   <div class="item-title">
                     <span>{{ item.gameTxtKey }}</span>
                   </div>
                   <div class="item-content">
                     <select v-model="item.rateList.pointRate">
                       <template v-for="(n, index) in item.rateLoop + 1" :key="index">
                         <option :value="(index * 0.05).toFixed(2)">
                           {{ (index * 0.05).toFixed(2) }}
                         </option>
                       </template>
                     </select>
                   </div>
                </div>
              </div>
            </template-->
            <div class="modal-btns">
             <a @click="rateUpdate(modal.target)">{{ $t('button.update') }}</a>
             <a @click="modalClose()">{{ $t('button.close') }}</a>
           </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
// import SearchBar from '@/components/main/top/SearchBar.vue'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import subTitle from '@/components/main/PageSubTitle'
import { GAME_INFO_LIST } from '@/libs/constants.js'
import { memberList, getCode, memberDetail, memberRateUpdate, getGameRollingList } from '@/api/member.js'
import { replaceDateT, numberWithCommas } from '@/libs/utils.js'

export default {
  name: 'UserList',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    subTitle,
    SearchFilter,
    Pagination,
    DateSelector
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: [
          'index',
          'memNick',
          'recommenderId',
          'sports',
          'minigame',
          'eSports',
          'kiron',
          'casino',
          'slot',
          'totalCashInAmt',
          'totalCashOutAmt',
          'totalCashDiff',
          'wl',
          'memRegDt',
          'lastLoginDt',
          'lastLoginIp',
          'empty'
        ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        recommenderId: '',
        memId: '',
        memNick: ''
      },
      searchSelect: 'memId',
      modal: {
        active: false,
        target: '',
        list: []
      },
      additionalHead: ['casino', 'eSports', 'game', 'slot', 'sports', 'kGame'],
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: false,
            opts: [
              {
                text: 'id',
                value: 'memId'
              },
              {
                text: 'nick',
                value: 'memNick'
              },
              {
                text: 'recommenderId',
                value: 'recommenderId'
              }
            ]
          }
        ]
      },
      selectOptions: [
        {
          useAll: true,
          opts: [
            {
              title: 'subUser',
              orders: ['many', 'few']
            }
          ]
        }
      ],
      checkList: [
        {
          name: 'betYn',
          checked: true,
          text: this.$t(`searchArea.rollNoSet`)
        },
        {
          name: 'recommenderYn',
          checked: true,
          text: this.$t(`searchArea.rollSet`)
        }
      ],
      tableData: [],
      btnText: '보기',
      memList: [],
      pageInfo: {},
      wlContentsActive: false,
      userRateList: [],
      rateList: [],
      gameList: [],
      detailGameList: [],
      groupCode: [],
      openGroup: '',
      casinoList: [],
      slotList: []
    }
  },
  methods: {
    searchSelectChange (e) {
      const target = e.target
      $target
      const value = target.value
      const children = target.children
      for (const item of children) {
        if (item.value !== value) {
          this.reqData[item.value] = ''
        }
      }
    },
    setRateList () {
      for (const loop of this.userRateList) {
        const groupCode = loop.groupCode
        const gameCode = loop.gameType
        if (loop.useYn === 'Y') {
          loop.useYn = true
        } else {
          loop.useYn = false
        }
        if (loop.betYn === 'Y') {
          loop.betYn = true
        } else {
          loop.betYn = false
        }
        const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()]
        if (gTxtCode) {
          const gameTextKey = this.$t(`gameList.${gTxtCode}`)
          const rateObj = {
            gameTxtKey: gameTextKey,
            rateLoop: 500,
            rateList: {
              gameType: gameCode,
              pointRate: loop.pointRate,
              useYn: loop.useYn,
              betYn: loop.betYn
            }
          }
          this.detailGameList.push(rateObj)
        }
      }
    },
    modalClose () {
      this.modal.target = ''
      this.userRateList = {}
      this.detailGameList = []
      this.modal.active = !this.modal.active
    },
    modalControl (memId) {
      this.getUserDetail(memId)
      this.modal.target = memId
      this.modal.active = !this.modal.active
    },
    pageSeach () {
      this.setTableData(1)
    },
    async getUserDetail (memId) {
      const reqData = {
        memId
      }
      const res = await memberDetail(reqData)
      this.userRateList = res.data.memberDetail.memberCommissionRate
      this.setRateList()
    },
    wlContentsToggle () {
      const reverseArr = this.additionalHead.reverse()
      const addPosition = this.headInfo.dataList.indexOf('wl') + 1
      this.wlContentsActive = !this.wlContentsActive
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item)
        } else {
          this.headInfo.dataList.splice(addPosition, 1)
        }
      }
    },
    setYn (value, name) {
      this.reqData[name] = value
      console.log(this.reqData[name])
    },
    async rateUpdate (memId) {
      const reqData = {
        memId,
        rateList: []
      }
      for (const item of this.detailGameList) {
        console.log(item)
        reqData.rateList.push(item.rateList)
      }
      for (const item of reqData.rateList) {
        if (item.betYn) {
          item.betYn = 'Y'
        } else {
          item.betYn = 'N'
        }
        if (item.useYn) {
          item.useYn = 'Y'
        } else {
          item.useYn = 'N'
        }
      }
      const res = await memberRateUpdate(reqData)
      if (res.resultCode === '0') {
        alert('요율변경완료 완료')
      } else {
        alert('요율변경실패, 다시 시도해주세요.')
      }
    },
    async getGameList () {
      const gameList = await getCode('kplay')
      this.gameList = gameList
      // console.log(this.gameList);
      const groupArr = []
      for (const item of gameList) {
        const groupCode = item.groupCode
        // console.log(groupCode);
        const gameCode = item.code
        // console.log(gameCode);
        if (item.groupCode === 'casino') {
          const _code = GAME_INFO_LIST[groupCode]
          const _text = _code[gameCode.toString()]
          const gTxtCode = _text

          if (gTxtCode) {
            const obj = {
              code: item.code,
              text: this.$t(`gameList.${_text}`)
            }
            this.casinoList.push(obj)
          }
        }
        if (item.groupCode === 'slot') {
          const _code = GAME_INFO_LIST[groupCode]
          const _text = _code[gameCode]
          const gTxtCode = _text
          if (gTxtCode) {
            const obj = {
              code: item.code,
              text: this.$t(`gameList.${_text}`)
            }
            this.slotList.push(obj)
          }
        }

        if (groupArr.indexOf(item.groupCode) === -1) {
          groupArr.push(item.groupCode)
        }
      }
      console.log(this.casinoList)
      console.log(this.slotList)
      this.groupCode = groupArr
    },
    setOpenGroup (game) {
      if (this.openGroup === game) {
        this.openGroup = ''
      } else {
        this.openGroup = game
      }
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }

      console.log(this.reqData)
      const res = await getGameRollingList(this.reqData)
      if (res.resultCode === '0') {
        console.log(res)
        const pageInfo = res.data.pageInfo
        this.pageInfo = pageInfo
        this.memList = res.data.list
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.pt50 {padding-top: 50px;}
.modal-wrapper button {width: auto;}
.fx {gap: 10px;}
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
.modal-item {
  width: 38%;
  margin: 0 auto;
  position: relative;
  top: 20%;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #6f7172;
  padding: 10px;
}
.s-content-wrap {
  max-height: 350px;
}
.s-content {
  flex-basis: 18%;
}
.s-content > .title {
  margin-bottom: 0;
  background: #18698b;
  white-space: nowrap;
  color: #fff;
}
.sub-title > h3 {
  width: fit-content;
}
.btn-wrap {
  margin: 10px;
}
.btn-wrap > button {
  font-size: 14px;
}
.openHead {
  cursor: pointer;
  user-select: none;
}
.rate-column{
  min-width:80px;
}
.row-double-data{
  line-height:1.4em;
}
.table-wrapper{
  overflow-x: scroll;
}
.modal-wrapper {display: flex;}
</style>
